const pricingtableTitle     = '.js-pricing-table-title';
const pricingTableTabs      = '.js-pricing-table-tabs';
const pricingTableTabFirst  = '.c-pricing-table__tabs li:first';
const pricingTableItemFirst = '.c-pricing-table .c-pricing-table__right .c-pricing-table__item:first';
const pricingTableTabLink   = '.js-tab-link';

var tabs = {

    init: function() {

        $(pricingTableTabFirst).addClass('is-active');
        $(pricingTableItemFirst).addClass('is-active');

        $(pricingTableTabs).each(function(){
            var $this = $(this);
            var $tab = $this.find('li.is-active');
            var $link = $tab.find('a');
            var $panel = $($link.attr('href'));
          
            $this.on('click', pricingTableTabLink, function(e) {
                e.preventDefault();
                var $link = $(this),
                id = this.hash;
                
                var title = $(this).text();
                $(pricingtableTitle).text(title);
          
                if (id && !$link.is('.is-active')) {
                    $panel.removeClass('is-active');
                    $tab.removeClass('is-active');
            
                    $panel = $(id).addClass('is-active');
                    $tab = $link.parent().addClass('is-active');
                }
            });
        });

    }
    
};

module.exports = tabs;

