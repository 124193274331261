const stateClasses = require('../utils/stateClasses');

const accordionsClass = '.js-accordion';
const accordionItemsClass = '.js-accordion-item';
const accordionHeaderClass = '.js-accordion-header';
const accordionContentClass = '.js-accordion-content';

var accordions = {

    init: function() {

        // Get all accordions
        var $accordions = $(accordionsClass);

        $accordions.each(function() {

            // Get the items and headers for this accordion
            var $accordion = $(this);
            var $items = $accordion.find(accordionItemsClass);
            var $headers = $accordion.find(accordionHeaderClass);
            var isAnimating = false;

            $items
                .prop('open', true)
                .find(accordionContentClass)
                    .hide();

            // Attach click event to headers
            $headers.on('click', function(e) {
                e.preventDefault();

                if (isAnimating) {
                    return false;
                }

                isAnimating = true;

                // find the current (clicked) item
                var $item = $(this).closest(accordionItemsClass);

                var isOpen = $item.data('accordionIsOpen');

                if (isOpen === false || typeof isOpen === 'undefined') {

                    // hide content all others
                    var $otherItems = $items.not($item);
                    $otherItems
                        .removeClass(stateClasses.isOpen)
                        .find(accordionContentClass)
                            .slideUp(() => {
                                isAnimating = false;
                                $otherItems.data('accordionIsOpen', false);
                            });

                    // show content of this item
                    $item
                        .addClass(stateClasses.isOpen)
                        .find(accordionContentClass)
                            .slideDown(() => {
                                isAnimating = false;
                                $item.data('accordionIsOpen', true);
                            });
                    
                } else {

                    $item
                        .removeClass(stateClasses.isOpen)
                        .find(accordionContentClass)
                            .slideUp(() => {
                                isAnimating = false;
                                $item.data('accordionIsOpen', false);
                            });

                }
            });
        });

    }
    
};

module.exports = accordions;