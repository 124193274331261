const bodyClass = 'body';
const menuButton = '.js-menu-button';
const navigationMenu = '.js-navigation-menu';

var navigation = {

    init: function() {

        $( ".c-navigation ul li.menu-item-has-children a" ).not('.sub-menu li a').after( "<div class='c-nav-dropdown'></div>" );

        $( ".c-nav-dropdown" ).click(function(){
            $(this).parent().find('ul.sub-menu').toggleClass('is-active');
        });

        $(menuButton).click(function(e){
            $(bodyClass).toggleClass('c-page-fixed');
            $(this).toggleClass("is-active");
            $(navigationMenu).toggleClass("is-active");
            e.preventDefault();
        });
        
    }
    
};

module.exports = navigation;