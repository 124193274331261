var beforeAfter = {

    init: function() {
        
        $(".slider-1").on("input change", (e) => {
            const sliderPos = e.target.value;
            // Update the width of the foreground image
            $('.c-before-after-fg-img--1').css('width', `${sliderPos}%`);
            // Update the position of the slider button
            $('.c-before-after-button--1').css('left', `calc(${sliderPos}% - 18px)`);
        });
        
        $(".slider-2").on("input change", (e) => {
            const sliderPos = e.target.value;
            // Update the width of the foreground image
            $('.c-before-after-fg-img--2').css('width', `${sliderPos}%`);
            // Update the position of the slider button
            $('.c-before-after-button--2').css('left', `calc(${sliderPos}% - 18px)`);
        });
        
        $(".slider-3").on("input change", (e) => {
            const sliderPos = e.target.value;
            // Update the width of the foreground image
            $('.c-before-after-fg-img--3').css('width', `${sliderPos}%`);
            // Update the position of the slider button
            $('.c-before-after-button--3').css('left', `calc(${sliderPos}% - 18px)`);
        });

    }
    
};

module.exports = beforeAfter;