const stickyHeader = '.js-sticky-header';

var slider = {

    init: function() {

        $(window).scroll(function(){
            var sticky = $(stickyHeader),
                scroll = $(window).scrollTop();
          
            if (scroll >= 300) {
                sticky.addClass('js-fixed');
            } else {
                sticky.removeClass('js-fixed');
            }
        });
    }
};

module.exports = slider;