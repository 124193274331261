const stateClasses = require('../utils/stateClasses');
const events = require('./events');

const classes = {
    overlay: 'js-overlay',
    toggle: 'js-overlay-toggle',
    close: 'js-overlay-close',
};

const $overlayToggles = $(`.${classes.toggle}`);
const $overlayCloseButtons = $(`.${classes.close}`);
const $overlays = $(`.${classes.overlay}`);

const overlays = {
    toggle () {
        $overlayToggles.on('click', function () {
            console.log('clicked');
            let overlay = $(this).data('overlay');
            let $overlay = $(overlay);
            if ($overlay.length > 0) {
                $overlay.toggleClass(stateClasses.isOpen);
            } else {
                console.log('Overlay toggle cannot find the overlay.');
            }
        });
    },
    closeAll () {
        $overlays.removeClass(stateClasses.isOpen);
    },
    close () {
        // const self = this;
        $overlayCloseButtons.on('click', function(){
            let $closestOverlay = $(this).closest(`.${classes.overlay}`);
            $closestOverlay.removeClass(stateClasses.isOpen);
        });
    },
    init () {
        const self = this;
        this.toggle();
        this.close();
        events.add('escape', self.closeAll);
    }
};  

module.exports = overlays;