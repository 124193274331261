const logoSlider = '.js-logo-slider';
const testimonialSlider = '.js-testimonial-slider';
const blogSlider = '.js-blog-slider';

var slider = {

    init: function() {

        // Logo Slider
        $(logoSlider).slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            variableWidth: true,
            prevArrow: $('.c-logo-slider__arrow--prev'),
            nextArrow: $('.c-logo-slider__arrow--next'),
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        infinite: true
                    }
                }, 
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ]
        });

        // Testimonials Slider
        $(testimonialSlider).slick({
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true,
            prevArrow: $('.c-testimonial-slider__arrow--prev'),
            nextArrow: $('.c-testimonial-slider__arrow--next'),
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        arrows: false,
                        slidesToShow: 1
                    }
                }
            ]
        });

        // Blog Slider (Mobile Only)
        const slider = $(blogSlider);
        const settings = {
            slidesToShow: 1,
            centerMode: true,
            mobileFirst: true,
            dots: true,
            arrows: false,
            responsive: [{
                breakpoint: '900',
                settings: "unslick"
            }]
        };

        slider.slick(settings);

        $(window).resize( function() {
            if(window.innerWidth < 900) {
                if(!$(blogSlider).hasClass('slick-initialized')){
                    return slider.slick(settings);
                }
            } else {
                if($(blogSlider).hasClass('slick-initialized')){
                    $(blogSlider).slick('unslick');
                }
            }
        });

    }
    
};

module.exports = slider;