const tabLink = '.js-tab-link';
const tabTop = '.js-scroll-top';

var scroll = {

    init: function() {
        $(tabLink).click(function() {
            $('html,body').animate({
                scrollTop: $(tabTop).offset().top-100
            },'slow');
        });
    }
    
};

module.exports = scroll;