var lightbox = {

    init: function() {

        

    }
    
};

module.exports = lightbox;