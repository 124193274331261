// Load jquery and assign to window object
window.$ = window.jQuery = require('jquery');
require('focus-visible');

// Load files
const accordions = require('./components/accordions');
const events = require('./components/events');
const navigation = require('./components/navigation');
const overlays = require('./components/overlays');
const slider = require('./components/slider');
const sticky = require('./components/sticky');
const youtube = require('./components/youtube');
const vimeo = require('./components/vimeo');
const lightbox = require('./components/lightbox');
const beforeAfter = require('./components/before-after');
const tabs = require('./components/tabs');
const scroll = require('./components/scroll');
const AOS = require('aos');
require('./components/match-height');
require('./components/popup');
require('./components/scripts');

// On DOM ready
$(function() {

	// Animations
	AOS.init();

	// Accordions
	accordions.init();

	// Events
	events.init();

	// Navigation
	navigation.init();

	// Overlays
	overlays.init();

	// Sliders
	slider.init();

	// Sticky
	sticky.init();

	// YouTube Videos
	youtube.init();

	// Vimeo Vides
	vimeo.init();

	// Lightbox
	lightbox.init();

	// Before/After Sliders
	beforeAfter.init();

	// Tabs
	tabs.init();

	// Scroll
	scroll.init();

});