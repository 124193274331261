jQuery('document').ready(function($){
    $("#menu-menu-header-uber li ul.sub-menu li a[href='#']").css('color','#BD9964');

    $('.js-gallery').magnificPopup({
        delegate : 'a',
        type : 'image',
        gallery : {
            enabled : true,
            navigateByImageClick : true,
        },
    }); 
    
    $('a.c-sticky-bar').attr('id', 'side-btn');
    $('.c-header__right a.c-button--primary').attr('id', 'banner-btn');
});

